import { Injectable } from '@angular/core';
import { HttpClient }  from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs/Observable";
import { EnvironmentSpecificService } from "../environment-specific.service";

@Injectable()
export class LuanchertServices {

    apiUrl:any;
    developmentEnv:boolean = true;
    test:any;
    constructor(private _http: HttpClient,private _envService: EnvironmentSpecificService) {

        this.apiUrl = this._envService.envSpecific.apiUrl;
        this.developmentEnv = this._envService.envSpecific.developmentEnv;
    }


    GetMaintenanceMode(): Observable<any> {

        return this._http.get(this.apiUrl  + '/system/getmaintenancemode').map((response: Response) => {

            return response;
        });
    }

    validateRecaptcha(Token: any): Observable<any> {
        let body = { "token": Token };

        return this._http.post(this.apiUrl.concat('/get-start/validaterecaptcha'), body).map((response: Response) => {

            return response;
        });
    }

    
    Login(data): Observable<any>{
        return this._http.post(this.apiUrl + '/get-start/login',data).map((response: Response) => {

            return response;
        });
    }
    
    LoginByPin(data): Observable<any>{
        return this._http.post(this.apiUrl + '/get-start/loginbypin',data).map((response: Response) => {

            return response;
        });
    }

    LoginByOTP(data): Observable<any>{
        return this._http.post(this.apiUrl + '/get-start/loginbyotp',data).map((response: Response) => {

            return response;
        });
    }

    RequestOTP(token): Observable<any> {
        var data = {
            token: token
        }
        return this._http.post(this.apiUrl + '/get-start/sendotptoken',data).map((response: Response) => {
            
            return response;
        });
    }
    
    ValidateUserAccount(email): Observable<any> {
        var data = {
            email: email
        };
        return this._http.post(this.apiUrl + '/get-start/validateuseraccount',data).map((response: Response) => {
            
            return response;
        });
    }

    ValidateOTPCode(refcode,otpcode,otptoken): Observable<any> {
        var data = {
            refcode: refcode,
            otpcode: otpcode,
            otptoken: otptoken
        };
        return this._http.post(this.apiUrl + '/get-start/validateotpcode',data).map((response: Response) => {
            
            return response;
        });
    }
    
    UpdateForgetPassword(newpassword,changepasswordtoken): Observable<any> {
        var data = {
            newpassword: newpassword,
            changepasswordtoken: changepasswordtoken
        };
        return this._http.post(this.apiUrl + '/users/updatepassword',data).map((response: Response) => {
            
            return response;
        });
    }

    SaveSession(data){
        
        localStorage.setItem('currentUser', JSON.stringify({
            username: data.username,
            id:data.id,
            name: data.name,
            photo: data.photo,
            token: data.token,
            role: data.role,
            account_id: data.account_id,
            socketToken: data.socketToken,
            antToken: data.antToken,
            is_admin: data.is_admin
        }));
    }

    ClearSession(){
        if(localStorage.getItem("currentUser")){
            localStorage.removeItem('currentUser');
        }
    }
}